import themeDefaults from '../bigcommerce-parts-warehouse/config.js';

themeDefaults.Widgets.find((w) => w.name === 'HeaderVehicleWidget').visibleIf = () =>
  window.location.pathname !== '/exhaust-shop-oakland-park/' &&
  window.location.pathname !== '/exhaust-shop-davie/';

function getLocalPreselection(pageType, defaults) {
  const bottomResultsContainer = window.document.querySelector('#cm-results-bottom');
  if (bottomResultsContainer && bottomResultsContainer.hasAttribute('data-category')) {
    const bottomResultsCategory = bottomResultsContainer.dataset.category;
    return [
      {
        field: 'category',
        term: bottomResultsCategory,
        treeLevel: bottomResultsCategory.split('>').length - 1,
      },
    ];
  }
  return defaults.getter(pageType);
}

export default {
  ...themeDefaults,
  includes: ['bigcommerce-parts-warehouse'],
  getLocalPreselection,
  Widgets: [
    ...themeDefaults.Widgets,
    {
      name: 'CategoryPage_bottom',
      type: 'SearchPage',
      location: '#cm-results-bottom',
      template: 'SearchPage',
    },
    {
      name: 'HeaderVehicleWidget_bottom',
      type: 'VehicleWidget',
      location: '#cm-ymm-bottom',
      template: 'fitmentSearch/collapsibleVehicleWidget',
      initCollapsed: true,
      columnBreakpoint: 800,
    },
  ],
};
